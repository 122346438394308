<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>

        <b-card no-body class="mb-0">

            <b-row>
                <b-col cols="12">
                    <b-card>
                        <b-card-title class="mb-1">

                            <b-row>
                                <b-col md="8">
                                    <span @click="$router.go(-1)">
                                        <img :src="require('@/assets/images/back.png')" style="width:30px;  margin-right:20px" align="left"/>
                                    </span>
                                    <h4 class="card-title float-left"> 
                                        {{ $route.params.sensor_name | capitalize }}
                                    </h4>                        
                                </b-col>
                                <b-col md="4">
                                    <PrintPdf :device_name="$route.params.sensor_name" :color="$route.params.color" :chartOptions="lineAreaChartSpline.chartOptions" :series="lineAreaChartSpline.series" :date_range="date_range" />             
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12">
                                    <div class="d-flex align-items-center">
                                        <feather-icon icon="CalendarIcon" size="32" />
                                        <flat-pickr
                                            v-model="date_range"
                                            :config="date_config"
                                            class="form-control flat-picker bg-transparent border-0 shadow-none"
                                            placeholder="DD MMM YYYY"
                                            style="width:200%"
                                            @on-change="getStats()"
                                        />
                                    </div>
                                </b-col>
                            </b-row>
                            

                        </b-card-title>

                        

                        <b-card-body>
                            <vue-apex-charts
                                type="area"
                                height="450"
                                ref="srjcchart"
                                :options="lineAreaChartSpline.chartOptions"
                                :series="lineAreaChartSpline.series"
                            />
                        </b-card-body>

                    </b-card>
                </b-col>

            </b-row>

        </b-card>
    </div>
</template>

<script>
    import {
        BCardHeader, BCardTitle, BCardText, BCardBody, BFormSelect, BFormSelectOption, BCard, BRow, 
        BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, 
        BPagination, BFormCheckbox, BAlert, BFormText, BFormGroup, BForm, BBreadcrumb
    } from 'bootstrap-vue'
    import { POST_API } from "../../../store/actions.type"
    import vSelect from 'vue-select';
    import VueApexCharts from 'vue-apexcharts'
    import flatPickr from 'vue-flatpickr-component'
    import moment from "moment-timezone";
    import { $themeColors } from '@themeConfig'
    import ChartjsComponentBarChart from '../charts-components/ChartjsComponentBarChart.vue'
    import PrintPdf from './PrintPdf';

    export default {
        components: {
            PrintPdf, ChartjsComponentBarChart, flatPickr, VueApexCharts, BCard, BCardHeader, BCardTitle, BCardText, 
            BCardBody, vSelect, BFormSelect, BFormSelectOption, BRow, BCol, BFormInput, BButton, BTable, 
            BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, vSelect, BFormCheckbox, 
            BAlert, BFormText, BFormGroup, BForm, BBreadcrumb
        },

        data() {
            return {
                webUrl: process.env.VUE_APP_SERVER_URL,
                error_message: null, 
                showDismissibleAlert:false,

                date_config:{ 
                    mode: 'range',
                    dateFormat: 'd M Y',
                    defaultDate: [
                        moment.utc().subtract(1,'days').format('DD MMM YYYY'),
                        moment.utc().format('DD MMM YYYY')
                    ]
                },

                date_range:moment.utc().subtract(1,'days').format('DD MMM YYYY') +' to '+moment.utc().format('DD MMM YYYY'),

                lineAreaChartSpline: {
                    "series": [], 
                    "chartOptions": {
                        colors: ['#'+this.$route.params.color],
                        "xaxis": {
                            type: 'datetime',
                        }, 
                        "yaxis": {
                            min: 0.1,
                            max: 100,
                            // labels: {
                            //     formatter: function(val) {
                            //         return val.toFixed(3);
                            //     }
                            // }
                        }, 
                        "dataLabels": { 
                            "enabled": false 
                        }, 
                        "stroke": { 
                            "show": false, 
                            "curve": "straight" 
                        }, 
                        "legend": { 
                            "show": true, 
                            "position": "top", 
                            "horizontalAlign": "left", 
                            "fontSize": "14px", 
                            "fontFamily": "Montserrat" 
                        }, 
                        "grid": { 
                            "xaxis": { 
                                "lines": { 
                                    "show": true 
                                } 
                            } 
                        }, 
                        "fill": { 
                            opacity: 1, 
                            type: "gradient",
                            gradient: {
                                shadeIntensity: 1,
                                inverseColors: false,
                                opacityFrom: 0.5,
                                opacityTo: 0,
                                stops: [0, 90, 100]
                            },
                        }, 
                        dataLabels: {
                            enabled: false,
                            style: {
                                fontSize: '14px',
                                fontFamily: 'Montserrat',
                                fontWeight: 'bold',
                                colors: ['#fff'],
                            },
                        },
                        stroke: {
                            curve: 'smooth'
                        },
                        tooltip: { 
                            enabled : true,
                            x: {
                                show: true,
                                format: 'hh:mm - dd MMM, yyyy'
                            },
                            y: {
                                title: {
                                    formatter: (seriesName) => seriesName+' - '
                                }
                            },
                            /* custom: function({series, seriesIndex, dataPointIndex, w}) {
                                console.log(series)
                                console.log(seriesIndex)
                                console.log(dataPointIndex)

                                return '<div class="arrow_box">' +
                                    '<span>' + series[seriesIndex][dataPointIndex] + '</span>' +
                                '</div>'
                            }, */
                            marker: {
                                show: true
                            }
                        }, 
                        chart: {
                            type: 'area',
                            stacked: false,
                            zoom: {
                                type: 'x',
                                enabled: true,
                                autoScaleYaxis: true
                            },
                            toolbar: {
                                autoSelected: 'zoom'
                            },
                            toolbar: {
                                show: true,
                                offsetX: 0,
                                offsetY: 0,
                                tools: {
                                    download: true,
                                    selection: true,
                                    zoom: true,
                                    zoomin: true,
                                    zoomout: true,
                                    pan: true,
                                    reset: true | '<img src="/static/icons/reset.png" width="20">',
                                    customIcons: []
                                },
                                export: {
                                    csv: {
                                        filename: this.$route.params.sensor_name,
                                        columnDelimiter: ',',
                                        headerCategory: 'Timestamp',
                                        headerValue: 'value',
                                        dateFormatter(timestamp) {
                                            return moment(timestamp).format('YYYY-MM-DD HH:mm')/* new Date(timestamp).toDateString() */
                                        }
                                    },
                                    svg: {
                                        filename: this.$route.params.sensor_name,
                                    },
                                    png: {
                                        filename: this.$route.params.sensor_name,
                                    }
                                },
                                autoSelected: 'zoom' 
                            },
                        },

                    } 
                },

            }
        },

        methods : {
            getStats(){                
                var date = this.date_range.split(' to ');

                return this.$store.dispatch(POST_API, {

                    data:{
                        device_id: this.$route.params.device_id,
                        sensor_id: this.$route.params.sensor_id,
                        sensor_name: this.$route.params.sensor_name,
                        range: [moment(new Date(date[0])).format('YYYY-MM-DD'), moment(new Date(date[1] ? date[1] : date[0])).format('YYYY-MM-DD')]
                    },
                    api: '/api/ble-sensor-stats'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        var data = this.$store.getters.getResults.data;
                        this.lineAreaChartSpline.series = data.series;

                        this.lineAreaChartSpline.chartOptions.xaxis.categories = data.categories;

                        this.$refs.srjcchart.updateSeries(this.lineAreaChartSpline.series, true);

                        return this.lineAreaChartSpline;
                    }
                });
            },
            breadCrumb(){
              var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
              },{
                to:null,
                text: 'Smart Sensors',
              },{
                to:{name:'smart-toilet'},
                text:'Devices'
              },{
                to:null,
                text:'Statistics'
              }];
              return item;
            }

        },

        mounted(){
            this.getStats();
        }
    }
</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
    @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
    @import '@core/scss/vue/libs/chart-apex.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>